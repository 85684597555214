import { format } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';

const downloadCSV = (object, device, valueOuter) => {
  const value = valueOuter || object.objectProperties.find((item) => item.key === 'valueValue')?.value || [];

  if (value.length === 0) {
    return;
  }

  let prepareForExport = [];

  value.forEach((item) => {
    const localItem = {
      'Date time': format(new Date(item?.timestamp || item?.time), 'yyyy-MM-dd HH:mm'),
    };

    item.values.forEach((itemValueInRow) => {
      localItem[`${itemValueInRow?.name ? itemValueInRow?.name : itemValueInRow?.id}`] = itemValueInRow.value;
    });

    prepareForExport.push(localItem);
  });

  const csvExporter = new ExportToCsv({
    title: device.value ? device.title : object.name,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    useTextFile: false,
    filename: device.value ? device.title : object.name,
    useBom: true,
    useKeysAsHeaders: true,
  });

  csvExporter.generateCsv(prepareForExport.reverse());
};

export { downloadCSV };

import { useApolloClient } from '@apollo/client';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import CommonModal from '../../../components/CommonModal';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import { msg } from '../../../constants/messages';
import { OBJECTS_QUERY } from '../../../graphql/queries';

const FilterGeotagsModal = (props) => {
  const client = useApolloClient();

  const [listOfObjects, setListOfObjects] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const queryObjects = async () => {
      setLoading(true);
      try {
        const result = await client.query({
          query: OBJECTS_QUERY,
          variables: {
            filter: {
              schemaTags: {
                contains: ['application', 'monitor', 'geotag'],
              },
            },
          },
          fetchPolicy: 'network-only',
        });

        setListOfObjects(
          result.data.objects.map((item) => {
            return { value: item.id, title: item.name };
          })
        );

        setValue(props.value);
        // setValues(defaultValues);
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        setLoading(false);
      }
    };

    queryObjects().then(() => {});
  }, []);

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    setValue(e.target.rawValue);
  };

  const handleLinkWidget = () => {
    props.save(value);
    submit();
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={'Select geotag'}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose} color="inherit">
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleLinkWidget}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {/* TODO: #351 */}
            <CustomAutocomplete
              disabled={isLoading}
              loading={isLoading}
              name="geotag"
              label={'Geotag *'}
              list={listOfObjects}
              value={value?.value ?? ''}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(FilterGeotagsModal);

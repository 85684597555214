import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { orderBy } from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import CommonModal from '../../../../components/CommonModal';
import { msg } from '../../../../constants/messages';
import { isMonitoringObject } from '../../../../utils/objectType';
import CustomAutocomplete from '../../../CustomAutocomplete';

export const GET_SCHEMA_PROPERTIES = gql`
  query schema($schemaId: UUID!) {
    schema(id: $schemaId) {
      schemaProperties {
        id
        property
        description
        groupName
        groupDescription
        units
      }
    }
  }
`;

// TODO: FSD, move to shared
const GET_PROPERTIES_BY_SCHEMA = gql`
  query getSchema($schemaId: UUID!) {
    schema(id: $schemaId) {
      mTags
      schemataByParentSchemaId(filter: { mTags: { equalTo: ["application", "monitor", "object monitoring item"] } }) {
        id
        name
        mTags
        schemaProperties(orderBy: [GROUP_NAME_ASC, DESCRIPTION_ASC]) {
          id
          key
          groupName
          property
          description
          type {
            name
          }
          valueSet
          valueRange
        }
      }
      schemaProperties(orderBy: [GROUP_NAME_ASC, DESCRIPTION_ASC]) {
        id
        typeId
        description
        property
        groupName
      }
    }
  }
`;

const ParameterPropertyModal = (props) => {
  const client = useApolloClient();

  const [properties, setProperties] = useState([]);

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), {
    property: null,
  });

  const [loadSchemaProperties] = useLazyQuery(GET_SCHEMA_PROPERTIES, {
    onCompleted: (e) => {
      console.log(e);
      setProperties(
        orderBy(
          e.schema.schemaProperties.map((item) => {
            return {
              value: item.id,
              title: item.description || item.property,
            };
          }),
          ['title'],
          ['asc']
        )
      );
    },
  });

  const getProperties = async () => {
    const schemaId = props.schemaId;
    if (schemaId) {
      // setLoading(true);
      try {
        const result = await client.query({
          query: GET_PROPERTIES_BY_SCHEMA,
          variables: {
            schemaId,
          },
          fetchPolicy: 'network-only',
        });

        const fragmentProperties = result.data.schema.schemataByParentSchemaId.map((item) => {
          const infoNameProperty = item.schemaProperties.find((prop) => prop.key === 'infoName');
          const stateValueProperty = item.schemaProperties.find((prop) => prop.key === 'stateValue');

          return {
            title: infoNameProperty ? infoNameProperty.value || item.name : null,
            value: stateValueProperty ? stateValueProperty.id : null,
          };
        });

        const ownProperties = result.data?.schema?.schemaProperties.map((item) => {
          return {
            value: item.id,
            title: `${item.groupName}/${item?.description || item.property}`,
          };
        });

        if (isMonitoringObject(result.data.schema.mTags)) {
          setProperties(fragmentProperties);
        } else {
          setProperties(ownProperties);
        }
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        // setLoading(false);
      }
    }
  };

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleLinkWidget = () => {
    props.save(values['property']);
    submit();
  };

  useEffect(() => {
    if (props.propertyId) {
      setValues({ property: props.propertyId });
    }
  }, [props.propertyId]);

  useEffect(() => {
    if (props.schemaId) {
      getProperties();
    }
  }, []);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title="Select property"
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleLinkWidget}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomAutocomplete
              disabled={!Boolean(props.schemaId)}
              value={values?.property?.value || props.propertyId}
              name="property"
              list={properties}
              fullWidth={true}
              label="Property name"
              clearFieldIcon={true}
              onChange={(e) => {
                setValues({ property: e.target.rawValue });
              }}
            ></CustomAutocomplete>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(ParameterPropertyModal);
